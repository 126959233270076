import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const Poemas = () => {
  return (
    <Layout>
      <h1>Poemas</h1>
      <div>
        <Link to="/poemas/abrazo-de-los-pueblos">
          <h5>Abrazo de pueblos</h5>
        </Link>
        <Link to="/poemas/asombro">
          <h5>Asombro</h5>
        </Link>
        <Link to="/poemas/cafayate-canto">
          <h5>Cafayate canto</h5>
        </Link>
        <Link to="/poemas/coplas">
          <h5>Coplas</h5>
        </Link>
        <Link to="/poemas/es-primavera">
          <h5>Es primavera</h5>
        </Link>
        <Link to="/poemas/eterno-viaje">
          <h5>Eterno viaje</h5>
        </Link>
        <Link to="/poemas/frutos-de-la-memoria">
          <h5>Frutos de la Memoria</h5>
        </Link>
        <Link to="/poemas/memoria-del-origen">
          <h5>Memoria del origen</h5>
        </Link>
        <Link to="/poemas/paisaje">
          <h5>Paisaje</h5>
        </Link>
      </div>
    </Layout>
  )
}

export default Poemas
